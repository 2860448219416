import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import "assets/css/custom.css";
import axios from 'axios';
import { Api_route } from '../../components/baseApi'
import Spinner from 'react-spinner-material';
import avatar from '../../assets/img/brand/logo.png';




class Login extends Component {

  constructor() {
    super()
    this.state = {
      isload_data: false,
      username: null,
      password: null,
      showErreur: false,
      message: null,
    }

  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  }
  changeLoaderVisiblility(visible) {
    this.setState({
      isload_data: visible
    });
  }

  submitHandeler = e => {
    e.preventDefault();

    const { username, password } = this.state;
    let Body = {
      "username": username,
      "password": password,

    };
    if (password != null && username != null) {
      this.changeLoaderVisiblility(true);
      axios.post(Api_route("login"), Body)
        .then(response => {
          // console.log(response.data.user);

          window.location.href = "/";

          window.sessionStorage.setItem("nameUser", response.data.user.name);
          window.sessionStorage.setItem("emailUser", response.data.user.email);
          window.sessionStorage.setItem("userID", response.data.user.serverId);
          window.sessionStorage.setItem("token", response.data.token);
          window.sessionStorage.setItem("phone", response.data.user.phone);
          this.changeLoaderVisiblility(false);

        })
        .catch(error => {
                  console.log("erreur",error);
         
          alert("Impossible de se connecter")
          this.changeLoaderVisiblility(false);

        })
      // console.log(username,password);


    } else {
      console.log("vide");
    }

  }



  render() {
    const { username, password } = this.state;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">

              <div className="col-lg-12 divloginlogo">
                <img
                  alt="..."
                  src={avatar}
                  className="loginlogo"

                />
              </div>

            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={this.submitHandeler}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nom d'utilisateur"
                      type="text"
                      autoComplete="new-email"
                      name="username"
                      value={username}
                      onChange={this.changeHandler}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Mot de pass"
                      type="password"
                      autoComplete="new-password"
                      name="password"
                      value={password}
                      onChange={this.changeHandler}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  {
                    !this.state.isload_data
                      ?

                      <button className="col-lg-12 btn btn_site" >
                        Connexion
                </button>
                      :
                      <div className="col-lg-12 loaderform">

                        <Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={this.state.isload_data} />
                      </div>
                  }
                </div>
              </Form>
            </CardBody>
          </Card>

        </Col>
      </>
    );
  }
};

export default Login;
