import React, {Component} from "react";

// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {Api_route} from '../../components/baseApi';
import axios from 'axios';
import Rodal from "rodal";

class Tables extends Component {

    constructor() {
        super()
        this.state = {
            dataSource: [],
            isload_data: true,
            totalPages: null,
            totalUsers: null,
            store_id: null,
            firstSaleDate: null,
            contact_mail: null,
            main_name: null,
            visible: false,


        }
        this.orderBy = "CREATION_DATE";
    }

    show() {
        this.setState({visible: true});
    }

    hide() {
        this.setState({visible: false});
    }


    Get_user_from_api(page, filter) {
        if (!page) {
            page = 0
        }
        this.setState({
            isload_data: true
        })
        const AuthStr = 'Bearer ' + window.sessionStorage.getItem("token");
        let url = null
        if (filter) {
            url = "torapos-customers-management/admins/" + window.sessionStorage.getItem("userID") + "?filter=" + filter + "&page=" + page + "&size=15&orderBy=" + this.orderBy
        } else {
            url = "torapos-customers-management/admins/" + window.sessionStorage.getItem("userID") + "?page=" + page + "&size=15&orderBy=" + this.orderBy;
        }
        axios.get(Api_route(url), {'headers': {'Authorization': AuthStr}})
            .then(response => {
                this.setState({
                    dataSource: response.data.content,
                    totalPages: response.data.totalPages,
                    isload_data: false,
                    totalUsers: response.data.totalElements,

                });
                console.log(response.data.totalElements);
            })
            .catch((error) => {
                console.log(error);
            });


    }

    componentDidMount() {
        this.Get_user_from_api();
    }

    rechercheHandler = e => {
        // console.log(e.target.value );
        this.Get_user_from_api(0, e.target.value);
    }

    changeOrderBy = e => {
        this.orderBy = e.target.value;
        this.Get_user_from_api();
    }


    render() {

        return (
            <>
                <Header/>
                {/* Page content */}
                <Rodal
                    visible={this.state.visible}
                    onClose={this.hide.bind(this)}
                    animation="zoom"
                    width={550}
                    closeOnEsc={true}
                >
                    <div className=" content">
                        <h3>Autres Détails</h3>
                        <div className="mt-5 ml-4 ">
                            <span className="text-dark">STORE ID</span>:<span
                            className="ml-4">{this.state.store_id}</span><br></br>
                            <span className="text-dark"> First Sale Date</span> :<span
                            className="ml-4">{new Date(this.state.firstSaleDate).toLocaleString()}</span><br></br>
                            <span className="text-dark">CONTACT EMAIL</span> :<span
                            className="ml-4">{this.state.contact_mail}</span><br></br>
                            <span className="text-dark">MAIN BUSINESS</span> :<span
                            className="ml-4">{this.state.main_name}</span><br></br>
                        </div>

                    </div>
                </Rodal>
                <Container className="mt--7" fluid>
                    {/* Table */}

                    {/* Dark table */}
                    <Row className="mt-5">
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 col-lg-12">
                                    <div className="row col-lg-12">
                                        <h3 className="text-white mb-0">Listes des utilisateurs
                                            ( {this.state.totalUsers ? this.state.totalUsers : "0"} )</h3>

                                        {/* <Spinner size={20} spinnerColor={"#fff"} spinnerWidth={1} visible={this.state.isload_data} /> */}

                                    </div>
                                    <div className="row col-12">
                                        <Form className="navbar-search navbar-search-dark form-inline   d-md-flex  ">
                                            <FormGroup className="mb-0">
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fas fa-search"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Search" type="text" name="recherche"
                                                           onChange={this.rechercheHandler}/>
                                                </InputGroup>
                                            </FormGroup>
                                        </Form>
                                        <form className="col-lg-4 ">
                                            <select className="form-control" name="oderby"
                                                    onChange={this.changeOrderBy}>
                                                <option value="CREATION_DATE">ORDER BY : CREATION DATE</option>
                                                <option value="LAST_SALE_DATE">ORDER BY: LAST SALE DATE</option>
                                                <option value="STORE_NAME">ORDER BY: STORE NAME</option>
                                                <option value="BUSINESS_NAME">ORDER BY: BUSINESS NAME</option>
                                                <option value="NUMBER_OF_PRODUCTS">ORDER BY: NUMBER OF PRODUCTS</option>
                                                <option value="NUMBER_OF_SALES">ORDER BY: NUMBER OF SALES</option>

                                            </select>

                                        </form>


                                    </div>


                                </CardHeader>
                                <Table
                                    className="align-items-center table-dark table-flush"
                                    responsive
                                    hover
                                >
                                    <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Store Name</th>
                                        <th scope="col">Owner Name</th>
                                        <th scope="col">Contact Number</th>
                                        <th scope="col">Store Address</th>
                                        <th scope="col">Number Of Products</th>
                                        <th scope="col">Number Of Sales</th>
                                        <th scope="col">Last Sale Date</th>
                                        <th scope="col">Creation Date</th>
                                        <th scope="col">action</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        this.state.dataSource
                                            ?
                                            this.state.dataSource.map((data, key) => {
                                                var creationDate = new Date(data.creationDate);
                                                var firstSaleDate = new Date(data.firstSaleDate);
                                                var lastSaleDate = new Date(data.lastSaleDate);
                                                return (

                                                    <tr key={key}
                                                        onClick={() => {
                                                            this.setState({
                                                                store_id: data.storeId,
                                                                firstSaleDate: data.firstSaleDate,
                                                                contact_mail: data.contactEmail,
                                                                main_name: data.mainBusinessName,
                                                            });
                                                            this.show(true)
                                                        }}

                                                    >
                                                        <th scope="row">
                                                            <Media className="align-items-center">

                                                                <Media>
                                    <span className="mb-0 text-sm">
                                      {data.storeName}
                                    </span>
                                                                </Media>
                                                            </Media>
                                                        </th>
                                                        <td>{data.ownerName}</td>
                                                        <td>
                                                            {data.contactNumber}
                                                        </td>
                                                        <td>
                                                            <span className="mr-2">{data.storeAddress}</span>
                                                        </td>
                                                        <td className="mr-2"><span>{data.numberOfProducts}</span></td>
                                                        <td className="mr-2"><span>{data.numberOfSales}</span></td>
                                                        <td className="mr-2">
                                                            <span>{data.lastSaleDate ? lastSaleDate.toLocaleString() : "No date"}</span>
                                                        </td>
                                                        <td className="mr-2">
                                                            <span>{data.creationDate ? creationDate.toLocaleString() : "No date"}</span>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn_site"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            store_id: data.storeId,
                                                                            firstSaleDate: data.firstSaleDate,
                                                                            contact_mail: data.contactEmail,
                                                                            main_name: data.mainBusinessName,
                                                                        });
                                                                        this.show(true)
                                                                    }}
                                                            >
                                                                <span className="fa fa-ellipsis-h"></span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            null
                                    }


                                    </tbody>


                                </Table>
                                {this.state.isload_data && <Spinner animation="grow" variant="primary"/>}
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >


                                            {
                                                this.state.totalPages
                                                    ?
                                                    Array.apply(null, {length: this.state.totalPages}).map((e, i) => (
                                                        <PaginationItem className="active">
                                                            <PaginationLink
                                                                href="#pablo"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    this.Get_user_from_api(i);

                                                                }}
                                                            >
                                                                {i + 1}

                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))
                                                    :
                                                    null
                                            }


                                        </Pagination>
                                    </nav>
                                </CardFooter>

                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
};

export default Tables;
