import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Listusers from "./layouts/listusers";



const App = (props) => {

  return (
    <BrowserRouter>

    { 
     window.sessionStorage.getItem("userID") && window.sessionStorage.getItem("token") && window.sessionStorage.getItem("nameUser")
     && window.sessionStorage.getItem("phone") && window.sessionStorage.getItem("emailUser")
     ?
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/admin/index" render={(props) => <AdminLayout {...props} />} />
      <Route path="/users/list" render={(props) => <Listusers {...props} />} />
      <Redirect from="/" to="/admin/index" />
    </Switch>
    :
       <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Redirect from="/" to="/auth/login" />
    </Switch>
    }
  </BrowserRouter>
  );
}
export default App;