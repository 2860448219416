import React from "react";
import {
 
  Navbar,
  
  Container,

} from "reactstrap";

const AdminNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-7">
        
          <button className="navbar-toggler" id="navbar-collapse-main">
          
          </button>
        
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
